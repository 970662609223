/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import type { CollapseProps } from "antd";
import { Button, Collapse, Select } from "antd";
// import Nav from "../../components/layout/nav";
import img from "../../assets/images/nr1.jpg";
import { RightOutlined, SettingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Nav from "../landing-pages/home-two/components/nav";
import Footer2 from "../landing-pages/home-two/components/footer2";

type ExpandIconPosition = "start" | "end";
export default function FAQ() {
  const [expandIconPosition, setExpandIconPosition] =
    useState<ExpandIconPosition>("start");

  const onPositionChange = (newExpandIconPosition: ExpandIconPosition) => {
    setExpandIconPosition(newExpandIconPosition);
  };

  const onChange = (key: string | string[]) => {};

  const genExtra = () => (
    <SettingOutlined
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
      }}
    />
  );

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: <div className="text-gray-600 font-bold">What is mCare?</div>,
      children: (
        <div className="text-gray-800">
          mCare - a subscription-based telemedicine platform developed by
          Medpharma in partnership with MTN. The product allows you to talk to a
          medical professional for your health needs wherever you are, provides
          you with an online pharmacy to purchase medication and have it
          delivered to you. You also benefit from a full package health OR a
          life insurance package.
        </div>
      ),
      //   extra: genExtra(),
    },
    {
      key: "2",
      label: (
        <div className="text-gray-600 font-bold">
          How many MCare Packages do we have?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          <p>There are 2 mCare packages: the Gold and Silver package.</p>

          <p>
            <strong>Gold Package</strong> costs GHC 161 per month and gets you
            the following:
          </p>
          <ul className="list-disc ml-5">
            <li>Full Package health insurance up to GHC 26,000</li>
            <li>Medication Cover and Refills</li>
            <li>E-consultation</li>
            <li>E-prescription</li>
            <li>Online Pharmacy</li>
            <li>Drug delivery</li>
            <li>Access to medical records on the app</li>
            <li>Health Content</li>
          </ul>

          <p>
            <strong>Silver Package</strong> costs GHC 10 per month and gets you:
          </p>
          <ul className="list-disc ml-5">
            <li>E-consultation</li>
            <li>E-prescription</li>
            <li>Online Pharmacy</li>
            <li>Drug delivery</li>
            <li>Access to medical records on the app</li>
            <li>Health Content</li>
            <li>
              Life insurance cover (Accident, Trauma, Death Cover of GHC 1000
              and Hospitalisation cover up to GHC 3,000—GHC 100 per day)
            </li>
          </ul>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div className="text-gray-600 font-bold">How can I access mCare?</div>
      ),
      children: (
        <div className="text-gray-800">
          You can access mCare using the link; www.mcare.medpharma.care, through
          the Medpharma App and medpharma website www.medpharma.care , the Ayoba
          App and the myMTN App.
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div className="text-gray-600 font-bold">
          Is there a waiting period before I can use mCare
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Yes, there is a 24-hour waiting period for mCare silver package
          customers and a 1-week waiting period for mCare Gold package
          customers. (It is 1 week for Gold package customers because of the
          health insurance component which takes a few days for our health
          insurance partner to process)
        </div>
      ),
    },
    {
      key: "5",
      label: (
        <div className="text-gray-600 font-bold">
          How many e-consultations can I have with a doctor?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Patients can have 2 e-consultations with an additional follow up call
          each month. Also, our medical professionals would occasionally call to
          check in on mcare customers.
        </div>
      ),
    },
    {
      key: "6",
      label: (
        <div className="text-gray-600 font-bold">
          Can I purchase medication through mCare?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Yes, with our e-pharmacy, you can buy medication and have it delivered
          to your preferred location. Our medical specialists may also prescribe
          medication and have them delivered to you after your e-consultation.
        </div>
      ),
    },
    {
      key: "7",
      label: (
        <div className="text-gray-600 font-bold">
          How are my medications delivered and how long do I have to wait for my
          medicines?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Medicines are delivered through our dispatch riders. How long it would
          take for you to receive your medicines would depend on the
          availability of the medication and your location.
        </div>
      ),
    },
    {
      key: "8",
      label: (
        <div className="text-gray-600 font-bold">
          Do I pay for my medication delivery?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Medication deliveries in and with the following locations () attract
          no fee, however, deliveries outside this catchment area would attract
          a delivery fee of GHS 20.
        </div>
      ),
    },
    {
      key: "9",
      label: (
        <div className="text-gray-600 font-bold">
          How long do I have to talk to a doctor?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          The e-consultations are 15 minutes long, we advice that you join the
          meeting as early as possible.
        </div>
      ),
    },
    {
      key: "10",
      label: (
        <div className="text-gray-600 font-bold">
          What if my consultation is longer than 15 minutes?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          We can arrange extended sessions with a medical professional if your
          consultations are longer than 15 minutes. Kindly reach out to our
          customer care agents on our toll-free number at (0308040082) or call
          0557560448. Please note extended sessions may attract an additional
          fee.{" "}
        </div>
      ),
    },
    {
      key: "11",
      label: (
        <div className="text-gray-600 font-bold">
          Are there any network requirements to access mCare?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Accessing mCare requires only a compatible device, such as an Android
          smartphone, iPhone, ipad, or laptop.
        </div>
      ),
    },
    {
      key: "12",
      label: (
        <div className="text-gray-600 font-bold">
          Can I access mCare from outside Ghana?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Yes you can access mCare outside Ghana.
        </div>
      ),
    },
    {
      key: "13",
      label: (
        <div className="text-gray-600 font-bold">
          How do I schedule a teleconsultation appointment?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          To schedule a teleconsultation appointment, simply login and navigate
          to the ‘appointment’ feature on the homepage. Enter what health issue
          you may have and select a date and time.
        </div>
      ),
    },
    {
      key: "14",
      label: (
        <div className="text-gray-600 font-bold">
          Are there any age restrictions for teleconsultation services?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Please be advised that teleconsultation services have age-related
          limitations for minors, it requires the presence of a Parent or
          Guardian to access these benefits.
        </div>
      ),
    },
    {
      key: "15",
      label: (
        <div className="text-gray-600 font-bold">How can I pay for mCare?</div>
      ),
      children: (
        <div className="text-gray-800">
          You can subscribe to mcare by navigating to your MCare account menu,
          select subscribe and and then choose your preferred plan.
          Subscriptions are billed automatically each month, so ensure your
          balance is up-to-date to avoid service interruptions.
        </div>
      ),
    },
    {
      key: "16",
      label: (
        <div className="text-gray-600 font-bold">
          Can I request prescriptions through teleconsultation?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Yes, prescriptions can be requested and provided during
          teleconsultation appointments, subject to the healthcare
          professional's assessment.
        </div>
      ),
    },
    {
      key: "17",
      label: (
        <div className="text-gray-600 font-bold">
          What happens if I need to do my labs?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          If labs are deemed essential following a teleconsultation, a doctor
          would make a lab request on your behalf and our customer service team
          will offer guidance on the recommended steps, which may involve
          arranging an appointment with a laboratory closest to you.
        </div>
      ),
    },
    {
      key: "18",
      label: (
        <div className="text-gray-600 font-bold">
          How do I know if a healthcare provider is part of the mCare network?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          To find an mCare network provider, use the provider’s list on your
          mCare app. We are adding more providers and updating regularly for
          your benefit.
        </div>
      ),
    },
    {
      key: "19",
      label: (
        <div className="text-gray-600 font-bold">
          Is there a waiting period for teleconsultation appointments?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          While booking teleconsultation appointments, you’ll have access to
          select your preferred time and date.
        </div>
      ),
    },
    {
      key: "20",
      label: (
        <div className="text-gray-600 font-bold">
          Are there any restrictions on the types of conditions covered under
          teleconsultation services?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Teleconsultation services cover a wide range of non-emergency medical
          conditions; however, certain specialized conditions may require
          in-person hospital visits and not e-consultations.
        </div>
      ),
    },
    {
      key: "21",
      label: (
        <div className="text-gray-600 font-bold">
          Can I access past teleconsultation records or medication history?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Yes, you can access your past teleconsultation records and medication
          history through your medical records tab.
        </div>
      ),
    },
    {
      key: "22",
      label: (
        <div className="text-gray-600 font-bold">
          Is it necessary for clients to have appointments with a doctor before
          refills?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Clients are advised to have e-consultations with a doctor at least
          once every 3 months to assess their health status before refills.
          First timers have to see a doctor first.
        </div>
      ),
    },
    // {
    //   key: "23",
    //   label: (
    //     <div className="text-gray-600 font-medium">
    //       Is there a dedicated service hub for walk-in client registration and
    //       enrollment?
    //     </div>
    //   ),
    //   children: (
    //     <div className="text-gray-800">
    //       Yes, we have agents at vantage points where clients can walk in for
    //       registration and enrollment on mCare
    //     </div>
    //   ),
    // },
    {
      key: "24",
      label: (
        <div className="text-gray-600 font-bold">
          Is my subscription fee refundable?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Subscriptions are non-refundable, but you can cancel your subscription
          at any time.
        </div>
      ),
    },
    {
      key: "25",
      label: (
        <div className="text-gray-600 font-bold">
          What services are included in mCare?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          mCare offers teleconsultation services, pharmaceutical products,
          medicines, hospitalisation, ambulance, medical emergency services, and
          health insurance options.
        </div>
      ),
    },
    {
      key: "26",
      label: (
        <div className="text-gray-600 font-bold">
          How do I renew my subscription?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          To renew your subscription, simply navigate to the side panel on your
          home screen and click on the 'Renew Plan' button. Then, follow the
          payment prompts to complete your renewal on the payment subscription
          page.
        </div>
      ),
    },
    // {
    //   key: "27",
    //   label: (
    //     <div className="text-gray-600 font-medium">
    //       What are the payment options for mCare?
    //     </div>
    //   ),
    //   children: (
    //     <div className="text-gray-800">
    //       Payments for mHealthCare can be made on various intervals: daily,
    //       monthly, quarterly, half-yearly, or yearly. Subscribers opting for
    //       longer payment intervals can enjoy discounts as follows: 3-month
    //       subscription pre-payment: 2.5% discount. 6-month subscription
    //       pre-payment: 5% discount. 9-month subscription pre-payment: 7.5%
    //       discount. 12-month subscription pre-payment: 10% discount.
    //     </div>
    //   ),
    // },
    {
      key: "28",
      label: (
        <div className="text-gray-600 font-bold">
          Can I change my subscription plan?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Yes, you can change from Silver to Gold and vice versa on your
          subscription plan at any time.
        </div>
      ),
    },
    {
      key: "29",
      label: (
        <div className="text-gray-600 font-bold">
          What happens if I miss a subscription payment?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Failure to pay will result in suspension or termination of services.
        </div>
      ),
    },
    {
      key: "30",
      label: (
        <div className="text-gray-600 font-bold">
          Are there any penalties for early cancellation of my subscription?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          No, there is no penalty for cancelling your subscription. However,
          refunds will not be provided on subscription cancellations.
        </div>
      ),
    },
    {
      key: "31",
      label: (
        <div className="text-gray-600 font-bold">
          Are there any age restrictions for subscribing to mCare?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Users must be within the age range of 0 to 59 years.
        </div>
      ),
    },
    // {
    //   key: "32",
    //   label: (
    //     <div className="text-gray-600 font-medium">
    //       Are there any penalties for early cancellation of my subscription?
    //     </div>
    //   ),
    //   children: (
    //     <div className="text-gray-800">
    //       No, there is no penalty for cancelling a health insurance policy.
    //       However, refunds will not be provided on subscription cancellations.
    //     </div>
    //   ),
    // },
    {
      key: "33",
      label: (
        <div className="text-gray-600 font-bold">
          What if I have a complaint or dispute regarding mCare services?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Complaints or disputes can be addressed through the designated
          customer support channels through our toll-free number at (0308040082)
          or call 0557560448 and appropriate measures will be taken to resolve
          them.
        </div>
      ),
    },
    {
      key: "34",
      label: (
        <div className="text-gray-600 font-bold">
          Are there any exclusions to the health insurance coverage?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Yes, certain services and conditions such as elective cosmetic
          procedures and dental care are excluded from coverage. Refer to terms
          and conditions for details.
        </div>
      ),
    },
    {
      key: "35",
      label: (
        <div className="text-gray-600 font-bold">
          How are claims processed under life insurance?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Claims are processed by our life insurance partner. Simply upload the
          required documents on the claims section in the app and a customer
          service agent would reach out to you for further assistance.
        </div>
      ),
    },
    {
      key: "36",
      label: (
        <div className="text-gray-600 font-bold">
          How do I know if my life insurance claim has been processed?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          You will receive notifications or updates regarding the status of your
          life insurance claims through the ‘Claims’ page on your dashboard.
        </div>
      ),
    },
    {
      key: "37",
      label: (
        <div className="text-gray-600 font-bold">
          Is there a system in place for emergencies such as road traffic
          accidents or strokes?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Yes, our system includes emergency protocols with designated emergency
          numbers on the health insurance cards that clients can call for
          assistance during emergencies like road traffic accidents, heart
          attacks, and other critical emergencies.
        </div>
      ),
    },
    {
      key: "38",
      label: (
        <div className="text-gray-600 font-bold">
          What benefits come with the Gold subscription?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          <p>
            For GHS 10 every month, the Silver subscription offers the following
            benefits:
          </p>
          <ul className="list-disc ml-5">
            <li>E-consultation.</li>
            <li>E-prescription.</li>
            <li>Death and accident (death) cover of GHS 3,000.</li>
            <li>
              Hospital cover of GHS 100 daily for a maximum of 30 days (3-day
              waiting period).
            </li>
            <li>Discounted drugs.</li>
            <li>Discounted delivery of medication.</li>
            <li>24/7 customer service.</li>
            <li>Free encrypted medical records.</li>
            <li>Access to medical records on the app.</li>
            <li>Chat with Kobikuul.</li>
            <li>Free and reliable wellness and disease prevention content.</li>
          </ul>

          <p>What benefits come with the Gold subscription?</p>

          <p>
            Health Insurance cover of GHS 26,000 which includes the following:
          </p>
          <ul className="list-disc ml-5">
            <li>E-consultation.</li>
            <li>E-prescription.</li>
            <li>Discounted drugs.</li>
            <li>Discounted delivery of medication.</li>
            <li>24/7 customer service.</li>
            <li>Free encrypted medical records.</li>
            <li>Access to medical records on the app.</li>
            <li>Chat with Kobikuul.</li>
            <li>Free and reliable wellness and disease prevention content.</li>
          </ul>

          <p>
            Outpatient GHS 6,000 (GHS 250 per hospital visit, maximum 2 visits
            per month) and includes:
          </p>
          <ul className="list-disc ml-5">
            <li>Medications - Covered within OPD limit per visit.</li>
            <li>Refill of Chronic Medications: GHS 100 covered per month.</li>
            <li>Diagnostic Services: Covered within OPD limit per visit.</li>
            <li>Dental Cover - GHS 100 annual cover.</li>
            <li>Lens & Frame - GHS 100 annual cover.</li>
            <li>
              Ambulatory Services: GHS 100 covered within OPD limit per visit;
              for emergency cases ONLY.
            </li>
          </ul>

          <p>
            In-patient cover GHS 20,000 annual (GHS 1,250 per month, activates
            on the 3rd day of admission with a GHS 70.00 cap for Ward
            Accommodation per day).
          </p>
          <ul className="list-disc ml-5">
            <li>Surgeries – GHS 1,500 per year.</li>
            <li>
              Maternity cover (Antenatal, Post-Natal, Delivery) GHS 2,000.
            </li>
            <li>Major disease conditions (Cancer, Stroke, etc).</li>
          </ul>
        </div>
      ),
    },

    {
      key: "39",
      label: (
        <div className="text-gray-600 font-bold">
          What are the benefits of the Silver subscription?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          <p>
            For GHS 10 every month, the Silver subscription offers the following
            benefits:
          </p>
          <ul className="list-disc ml-5">
            <li>E-consultation.</li>
            <li>E-prescription.</li>
            <li>Death and accident (death) cover of GHS 3,000.</li>
            <li>
              Hospital cover of GHS 100 daily for a maximum of 30 days (3-day
              waiting period).
            </li>
            <li>Discounted drugs.</li>
            <li>Discounted delivery of medication.</li>
            <li>24/7 customer service.</li>
            <li>Free encrypted medical records.</li>
            <li>Access to medical records on the app.</li>
            <li>Chat with Kobikuul.</li>
            <li>Free and reliable wellness and disease prevention content.</li>
          </ul>
        </div>
      ),
    },

    {
      key: "40",
      label: (
        <div className="text-gray-600 font-bold">
          Can I break down my Gold Package subscription of GHC 161.67 and pay a
          fraction of it on a weekly basis?
        </div>
      ),
      children: (
        <div className="text-gray-800">
          Yes, you can break down your Gold Package subscription of GHC 161.67
          and pay on a weekly basis. Here's how it breaks down: From the 1st to
          13th Week: Weekly Payment: GHC 40.42 Services included: E-consultation
          E-prescription GHC 200gh cover for medication and consultation (no
          diagnostics) No in-patient benefits Discounted medication Discounted
          drugs delivery From the 14th Week: Weekly Payment: GHC 40.42 Services
          included: E-consultation E-prescription Out-Patient benefits: GHC 250
          per hospital visit, maximum 2 visits per month Medications covered
          within OPD limit per visit Refill of Chronic Medications: GH¢ 100
          covered per month Diagnostic Services covered within OPD limit per
          visit Dental Cover: GH¢ 100 annual cover Lens & Frame: GH¢ 100 annual
          cover In-patient benefits: GHC 1,250 per month, activates on the 3rd
          day of admission with GHC 70.00 cap for Ward Accommodation per day
          Ambulatory Services: GH¢ 100 covered within OPD limit per visit; for
          emergency cases ONLY. So, you can pay GHC 40.42 per week for the first
          13 weeks, and then continue paying the same amount for subsequent
          weeks to enjoy the expanded benefits starting from the 14th week.
        </div>
      ),
    },
    // {
    //   key: "41",
    //   label: (
    //     <div className="text-gray-600 font-medium">
    //       How do I subscribe to mCare?
    //     </div>
    //   ),
    //   children: (
    //     <div className="text-gray-800">
    //       You can subscribe to mCare through a simple registration process on
    //       the Ayoba app, myMTN app or through the Medpharma app and the
    //       Medpharma chatbot.
    //     </div>
    //   ),
    // },
    // {
    //   key: "42",
    //   label: (
    //     <div className="text-gray-600 font-medium">
    //       Are there any age restrictions for subscribing to mCare?
    //     </div>
    //   ),
    //   children: (
    //     <div className="text-gray-800">
    //       Subscribers must be within the age range of 0 to 59 years.
    //     </div>
    //   ),
    // },
    // {
    //   key: "43",
    //   label: (
    //     <div className="text-gray-600 font-medium">
    //       Can I add family members to my mCare subscription?
    //     </div>
    //   ),
    //   children: (
    //     <div className="text-gray-800">
    //       Currently, we do not offer family or group plans for our mCare
    //       subscription. Each user would need to have their own separate
    //       subscription to access our services. If you have any further questions
    //       or need assistance, please feel free to contact our dedicated customer
    //       support team for more information.
    //     </div>
    //   ),
    // },
    // {
    //   key: "44",
    //   label: (
    //     <div className="text-gray-600 font-medium">
    //       How do I report issues or complaints with mCare?
    //     </div>
    //   ),
    //   children: (
    //     <div className="text-gray-800">
    //       You can report issues or lodge complaints through the designated
    //       customer care channel at support@medpharma.care, the mCare Customer
    //       Service chat, or call 055-756-0448.
    //     </div>
    //   ),
    // },
    // {
    //   key: "45",
    //   label: (
    //     <div className="text-gray-600 font-medium">
    //       How can I provide feedback or suggestions for improving mCare?
    //     </div>
    //   ),
    //   children: (
    //     <div className="text-gray-800">
    //       You can provide feedback or suggestions through the customer service
    //       and designated channels provided by mCare.
    //     </div>
    //   ),
    // },
    // {
    //   key: "46",
    //   label: (
    //     <div className="text-gray-600 font-medium">
    //       Is there a customer loyalty program for mCare?
    //     </div>
    //   ),
    //   children: (
    //     <div className="text-gray-800">
    //       Loyalty programs or rewards may be available for active subscribers;
    //       refer to promotional offers for details.
    //     </div>
    //   ),
    // },
    // {
    //   key: "47",
    //   label: (
    //     <div className="text-gray-600 font-medium">
    //       Can I opt out of receiving marketing communications from mCare?
    //     </div>
    //   ),
    //   children: (
    //     <div className="text-gray-800">
    //       Yes, you can manage your communication preferences through the
    //       settings or preferences section of the platform.
    //     </div>
    //   ),
    // },
    // {
    //   key: "48",
    //   label: (
    //     <div className="text-gray-600 font-medium">
    //       Are there any restrictions on the types of medications available for
    //       purchase?
    //     </div>
    //   ),
    //   children: (
    //     <div className="text-gray-800">
    //       Medications available for purchase may be subject to regulatory
    //       restrictions and may vary based on availability from our partner
    //       pharmacy and coverage by insurance.
    //     </div>
    //   ),
    // },
    // {
    //   key: "49",
    //   label: (
    //     <div className="text-gray-600 font-medium">
    //       Can clients communicate with customer support through the app?
    //     </div>
    //   ),
    //   children: (
    //     <div className="text-gray-800">
    //       Clients can conveniently communicate with our customer support team
    //       via the in-app chat feature. Alternatively, they have the option to
    //       contact our dedicated customer service hotlines for quick and
    //       efficient assistance.
    //     </div>
    //   ),
    // },
    // {
    //   key: "50",
    //   label: (
    //     <div className="text-gray-600 font-medium">
    //       Are there any exclusions to the health insurance coverage?
    //     </div>
    //   ),
    //   children: (
    //     <div className="text-gray-800">
    //       Yes, certain services and conditions such as elective cosmetic
    //       procedures and dental care are excluded from coverage. Kindly reach
    //       out to our designated customer care units for more information. Refer
    //       to terms and conditions for details.
    //     </div>
    //   ),
    // },
    // {
    //   key: "51",
    //   label: (
    //     <div className="text-gray-600 font-medium">
    //       How are claims processed under health insurance?
    //     </div>
    //   ),
    //   children: (
    //     <div className="text-gray-800">
    //       Claims are processed by our health insurance partner.
    //     </div>
    //   ),
    // },
    // {
    //   key: "52",
    //   label: (
    //     <div className="text-gray-600 font-medium">
    //       How do I know if my health insurance claim has been processed?
    //     </div>
    //   ),
    //   children: (
    //     <div className="text-gray-800">
    //       You will receive notifications or updates regarding the status of your
    //       health insurance claims through the designated communication channels.
    //     </div>
    //   ),
    // },
    // {
    //   key: "53",
    //   label: (
    //     <div className="text-gray-600 font-medium">
    //       How do I update my personal information or billing details?
    //     </div>
    //   ),
    //   children: (
    //     <div className="text-gray-800">
    //       Personal information and billing details can be updated through the
    //       settings or profile section of the mCare platform.
    //     </div>
    //   ),
    // },
    // {
    //   key: "54",
    //   label: (
    //     <div className="text-gray-600 font-medium">
    //       Is there a system in place for emergencies such as road traffic
    //       accidents or stroke?
    //     </div>
    //   ),
    //   children: (
    //     <div className="text-gray-800">
    //       Yes, our system includes emergency protocols with designated emergency
    //       numbers on the health insurance cards that clients can call for
    //       assistance during emergencies like road traffic accidents, heart
    //       attacks, and other critical emergencies.
    //     </div>
    //   ),
    // },
    // {
    //   key: "55",
    //   label: (
    //     <div className="text-gray-600 font-medium">
    //       How are medical records managed within mCare?
    //     </div>
    //   ),
    //   children: (
    //     <div className="text-gray-800">
    //       Medical records are managed securely and confidentially, adhering to
    //       strict privacy regulations and industry standards. All medical data is
    //       encrypted to ensure protection against unauthorized access.
    //     </div>
    //   ),
    // },
    // {
    //   key: "56",
    //   label: (
    //     <div className="text-gray-600 font-medium">
    //       Does the subscription include ambulance cover?
    //     </div>
    //   ),
    //   children: (
    //     <div className="text-gray-800">
    //       Yes, Gold Package holders receive ambulance services as part of their
    //       subscription within their monthly out-patient limits.
    //     </div>
    //   ),
    // },
    // {
    //   key: "57",
    //   label: (
    //     <div className="text-gray-600 font-medium">
    //       Can I access mCare on multiple devices?
    //     </div>
    //   ),
    //   children: (
    //     <div className="text-gray-800">
    //       Yes, you can access mCare on multiple devices using your login
    //       credentials.
    //     </div>
    //   ),
    // },
    // {
    //   key: "58",
    //   label: (
    //     <div className="text-gray-600 font-medium">
    //       How do I access teleconsultation services?
    //     </div>
    //   ),
    //   children: (
    //     <div className="text-gray-800">
    //       You can access teleconsultation services through the designated
    //       platforms (Ayoba, MyMTN App, or the MedPharma App), where you'll be
    //       connected with healthcare professionals.
    //     </div>
    //   ),
    // },
  ];
  const navigate = useNavigate();
  return (
    <div className="flex  w-full flex-col justify-start">
      <Nav />
      <div
        className="w-full flex justify-center items-center h-[50vh] bg-cover bg-[#1A94ECB5] bg-blend-overlay"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div className="ml-10 flex flex-col justify-center items-center mt-20">
          <h1 className="lg:text-6xl md:text-4xl text-3xl text-white font-bold">
            Frequently Asked Questions
          </h1>
          <div className="mt-8 flex flex-row items-center ">
            <Link
              to="/"
              className="text-[#ffffff]  hover:text-gray-300 text-xl font-semibold"
            >
              Home
            </Link>
            <div className=" mx-3 ">
              <RightOutlined style={{ color: "white", fontSize: "15px" }} />
            </div>
            <Link to="" className="text-[#ffffff] text-xl font-semibold">
              FAQs
            </Link>
          </div>
        </div>
      </div>
      <div className="py-6 bg-[#E1F2FEB5] w-full flex flex-col justify-center items-center ">
        <h1 className="font-bold md:text-3xl text-xl flex justify-center">
          Frequently Asked Questions
        </h1>

        {/* <Link
          to="/main/appointments/all"
          className="flex text-lg mt-5 items-center text-[#000]"
        >
          <h4 className="text-[#1A94EC] hover:text-[#ffffff]">Home </h4>
          <h4>/ Faqs</h4>
        </Link> */}
        <div className="w-full flex flex-col justify-start items-center bg-[#E1F2FEB5] pt-8 pb-4">
          <div className="mt-8 w-[70%]">
            <Collapse
              defaultActiveKey={[]}
              onChange={onChange}
              expandIconPosition="right"
              items={items}
              className="w-full bg-white"
              bordered
              //   size="small"
            />
          </div>
          <Button
            onClick={() => navigate("/")}
            size="large"
            className="bg-white mt-8 px-10 rounded"
          >
            Go to home
          </Button>
        </div>
      </div>
      <Footer2 />
    </div>
  );
}
