import React, { ReactNode, useEffect, useState } from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { FaStethoscope, FaUserFriends } from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";
import Account from "../../components/sections/account";
import Address from "./address";
import Mcare from "./mcare";
import MedicalRecords from "./medical-records";
import img from "../../assets/images/poweredbymtn.png";
import { SolutionOutlined } from "@ant-design/icons";

export default function Profile() {
  const navigate = useNavigate();
  const [tab, setTab] = useState("3");
  const params = useParams();

  function tabBar(icon: ReactNode, title: ReactNode) {
    return (
      <div className="flex items-center font-medium text-sm cursor-pointer">
        {icon} {title}
      </div>
    );
  }

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: tabBar(<FaUserFriends />, <p className="mx-2">Profile</p>),
      children: <Account tab={tab} setTab={setTab} />,
    },
    {
      key: "2",
      label: tabBar(<SolutionOutlined />, <p className="mx-2">Mcare</p>),
      children: <Mcare tab={tab} setTab={setTab} />,
    },
    // {
    //   key: "3",
    //   label: tabBar(<IoKeySharp />, <p className="mx-2">Insurance</p>),
    //   children: "Content of Tab Pane 3",
    // },
    {
      key: "4",
      label: tabBar(<CiLocationOn />, <p className="mx-2">Delivery Address</p>),
      children: <Address tab={tab} setTab={setTab} />,
    },
    {
      key: "5",
      label: tabBar(<FaStethoscope />, <p className="mx-2">Medical Records</p>),
      children: <MedicalRecords />,
    },
    // {
    //   key: "6",
    //   label: tabBar(<IoKeySharp />, <p className="mx-2">Corporate Account</p>),
    //   children: "Content of Tab Pane 3",
    // },
    // {
    //   key: "7",
    //   label: tabBar(<IoChatbox />, <p className="mx-2">Contact Us</p>),
    //   children: "Content of Tab Pane 3",
    // },
  ];

  const onChange = (key: string) => {
    setTab(key);
    navigate(`/main/profile/${key}`);
  };

  useEffect(() => {
    const { id } = params;
    if (id) {
      setTab(id);
    }
  }, [params]);

  return (
    <div className="my-4">
      <Tabs
        animated
        activeKey={tab}
        type="card"
        className="w-full"
        // style={{ height: 220 }}
        items={items}
        onChange={onChange}
        indicator={{ size: 200 }}
      />
      <div>
        {" "}
        <div className="flex justify-center mt-5">
          <img src={img} alt="care" className="md:h-20 h-10 rounded-l-lg" />
        </div>
      </div>
    </div>
  );
}
