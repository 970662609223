import React, { useCallback, useEffect, useState } from "react";
import med from "../../assets/images/mcarehd.png";
import moment from "moment";
import img from "../../assets/images/card.png";
import usr from "../../assets/images/user.png";
import DataProvider from "../../services";
import { useNavigate } from "react-router-dom";
import mtn from "../../assets/images/mtn.png";
import mcare from "../../assets/images/pho.png";
import { useAppStore } from "../../store";
import logo from "../../assets/images/md_logo.png";
import Notsubscribedcarddesktop from "./notsubscribed-desktop-card";
import LoadWrapper from "../elements/load-wrapper";

export default function SubscriptionCard(props: any) {
  const { user } = useAppStore();
  const displayImage: string = user && user.picture ? user.picture : usr;
  const navigate = useNavigate();
  // const [api, contextHolder] = notification.useNotification();
  // const [subscriptionCheck, setSubscriptionCheck] = useState<
  //   typeof subscriptionCheckData
  // >([]);
  const [subscription, setSubscription] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getSubscription = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await DataProvider.getUserSubscription();
      if (data.message) {
        setLoading(false);
        return;
      }
      setSubscription(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getSubscription();
  }, [getSubscription]);

  return loading ? (
    <LoadWrapper loading={loading}>
      <div className="bg-white md:h-80 h-68 shadow rounded-xl p-4 border-1 bg-cover grid grid-cols-1 gap-4 items-center "></div>
    </LoadWrapper>
  ) : subscription !== null &&
    subscription.status !== "activation_failed" &&
    subscription.status !== "pending" &&
    subscription.status !== "cancelled" ? (
    <div
      className="bg-white md:h-80 h-68 md:p-4 p-3 shadow rounded-xl bg-cover"
      style={{ backgroundImage: `url(${img})` }}
    >
      <img src={med} alt="" className="md:h-10 h-6 md:mb-4 mb-2" />
      <div className="w-full flex justify-between items-start">
        <div className="">
          <div className="flex justify-start w-full">
            <div className="mb-2 mr-10 sm:mr-24">
              <h4 className="sm:text-base text-[9px] font-semibold text-[#06509E]">
                Mcare Plan
              </h4>
              <p className="text-xs font-semibold text-gray-700">
                {subscription.mcare.name}
              </p>
            </div>
            <div className="mb-2">
              <h4 className="sm:text-base text-[9px] font-semibold text-[#06509E]">
                Membership Number
              </h4>
              <p className="text-xs font-semibold text-gray-700">
                {subscription.code}
              </p>
            </div>
          </div>
          <div className="flex justify-start">
            <div className="mb-2 mr-10 sm:mr-24">
              <h4 className="sm:text-base text-[9px] font-semibold text-[#06509E]">
                Expiry Date
              </h4>
              <p className="text-xs font-semibold text-gray-700">
                {subscription.endDate
                  ? moment(subscription.endDate).format("Do MMMM, YYYY")
                  : "Expired"}
              </p>
            </div>
            <div className="mb-2">
              <h4 className="sm:text-base text-[9px] font-semibold text-[#06509E]">
                Status
              </h4>
              <p className="text-xs font-semibold  capitalize text-gray-700">
                {subscription.status}
              </p>
            </div>
          </div>
          {/* {subscription.insuranceForm === null && (
            <div className="mt-3 md:w-[75%] w-full">
              <p className="font-medium text-sm mb-1">
                Kindly complete this{" "}
                <Link
                  target="_blank"
                  to="https://forms.gle/G9X9WpanzSFL4KGd7"
                  className="font-bold text-sm text-red-500"
                >
                  FORM
                </Link>{" "}
              </p>
              <div className="sm:w-[68%] w-[63%] flex items-center md:justify-end justify-between">
                <p className="sm:text-xs text-[10px] sm:mr-1">
                  Registration completion
                </p>
                <p className="sm:text-xs text-[11px]">60%</p>
              </div>
              <div className="w-full h-2 bg-gray-50 border border-solid border-gray-400 rounded-full">
                <div className="h-full bg-[#06509E] rounded-full w-[50%]"></div>
              </div>
            </div>
          )} */}
        </div>
        <div>
          <div
            onClick={() => navigate("/main/profile/1")}
            // className="h-32 w-32 rounded-full border"
            className="lg:h-40 h-32 lg:w-40 w-32 lg:mr-8 rounded-full"
          >
            <img
              src={displayImage}
              alt=""
              className="h-full w-full rounded-full"
            />
          </div>
        </div>
      </div>
      <div className="flex mt-4">
        <img src={mtn} alt="care" className="md:h-6 h-3 mx-1`" />
        <img src={mcare} alt="care" className="md:h-6 h-3 mx-3" />
        <img src={logo} alt="care" className="md:h-6 h-3 mx-3" />
      </div>
      {/* {contextHolder} */}
    </div>
  ) : (
    <Notsubscribedcarddesktop action={getSubscription} />
  );
}
