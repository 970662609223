/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
// import type { CollapseProps } from "antd";
import { Button, Collapse, Select } from "antd";

import img from "../../assets/images/nr1.jpg";
import { RightOutlined, SettingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Nav from "../landing-pages/home-two/components/nav";
import Footer2 from "../landing-pages/home-two/components/footer2";

type ExpandIconPosition = "start" | "end";
export default function FAQ() {
  const [expandIconPosition, setExpandIconPosition] =
    useState<ExpandIconPosition>("start");

  const onPositionChange = (newExpandIconPosition: ExpandIconPosition) => {
    setExpandIconPosition(newExpandIconPosition);
  };

  const onChange = (key: string | string[]) => {};

  const genExtra = () => (
    <SettingOutlined
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
      }}
    />
  );

  const navigate = useNavigate();
  return (
    <div className="flex  w-full flex-col justify-start">
      {/* <Nav /> */}
      <Nav />
      <div
        className="w-full flex justify-center items-center h-[50vh] bg-cover bg-[#1A94ECB5] bg-blend-overlay"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div className="ml-10 flex flex-col justify-center items-center mt-20">
          <h1 className="lg:text-6xl md:text-4xl text-3xl text-white font-bold">
            Terms And Conditions
          </h1>
          <div className="mt-8 flex flex-row items-center ">
            <Link
              to="/"
              className="text-[#ffffff]  hover:text-gray-300 text-xl font-semibold"
            >
              Home
            </Link>
            <div className=" mx-3 ">
              <RightOutlined style={{ color: "white", fontSize: "15px" }} />
            </div>
            <Link to="" className="text-[#ffffff] text-xl font-semibold">
              T&C's
            </Link>
          </div>
        </div>
      </div>
      <div className="py-6 bg-[#E1F2FEB5]  px-2 lg:px-24 ">
        {/* <h1 className="font-bold text-xl mb-2">Terms And Conditions</h1> */}

        <h1 className="font-bold text-3xl text-center mb-8">
          MCare General Terms and Conditions
        </h1>

        <h2 className="font-bold text-xl mb-2">Medical Advice and Treatment</h2>
        <p className=" mb-7">
          Our e-consultation services provide medical advice and treatment based
          on the information provided by the patient. It is essential for
          patients to provide accurate and complete information to receive
          appropriate care.
        </p>

        <h2 className="font-bold text-xl mb-2">Confidentiality and Privacy</h2>

        <p className=" mb-7">
          We are committed to maintaining the confidentiality and privacy of
          your medical information. All consultations and records are protected
          in accordance with applicable privacy laws and regulations.
        </p>

        <h2 className="font-bold text-xl mb-2">Technology Requirements</h2>
        <p className=" mb-7">
          Patients are responsible for ensuring they have the necessary
          technology, including a stable internet connection and compatible
          device, to access our e-consultation services.
        </p>

        <h2 className="font-bold text-xl mb-2">Payment and Fees</h2>
        <p className=" mb-7">
          Consultation services are provided through a monthly subscription
          payment model. Subscription fees must be paid in advance at the start
          of each billing cycle using MTN Mobile Money. Patients are responsible
          for ensuring that their subscription is kept current to avoid any
          interruptions in service. No other payment methods are accepted.
        </p>

        <h2 className="font-bold text-xl mb-2">
          Limitations of E-consultation
        </h2>
        <p className=" mb-7">
          While e-consultation provides a convenient way to access healthcare,
          it has limitations. Certain conditions may require in-person
          examination and treatment. Our practitioners will advise if an
          in-person consultation or medical examination is necessary.
        </p>

        <h2 className="font-bold text-xl mb-2">
          Cancellation and Rescheduling
        </h2>
        <p className=" mb-7">
          Patients may cancel or reschedule their appointments before 8 hours to
          consultation without penalty. Cancellations or rescheduling requests
          made 8 hours to a consultation may attract a fee.
        </p>

        <h2 className="font-bold text-xl mb-2">Emergency Situations</h2>
        <p className=" mb-7">
          In the event of an emergency, we have provided a designated emergency
          number for our patients. This service is offered at no fee to Gold
          package customers. However, Silver package customers will incur a fee
          for using the designated emergency number.
        </p>

        <h2 className="font-bold text-xl mb-2">User Conduct</h2>
        <p className=" mb-7">
          Patients are expected to conduct themselves respectfully during
          consultations. Abusive, offensive, or inappropriate behavior may
          result in termination of services.
        </p>

        <h2 className="font-bold text-xl mb-2">Consent to Treatment</h2>
        <p className=" mb-7">
          By using our e-consultation services, patients consent to receive
          medical treatment through virtual consultations. Patients have the
          right to withdraw consent at any time.
        </p>

        <h2 className="font-bold text-xl mb-2">Prescription Policy</h2>
        <p className=" mb-7">
          Prescriptions provided through e-consultations will be issued based on
          the practitioner's professional judgment. Not all medications are
          eligible for prescription via e-consultation.
        </p>

        <h2 className="font-bold text-xl mb-2">Age Restrictions</h2>
        <p className=" mb-7">
          Our services cater to patients from 0 to 59 years. For patients under
          18 years old, parental or guardian consent is required for
          e-consultations. Furthermore, parents or guardians must be present at
          all times during their child's e-consultations to ensure appropriate
          communication.
        </p>

        <h2 className="font-bold text-xl mb-2">Third-Party Services</h2>
        <p className=" mb-7">
          Our e-consultation platform may integrate with third-party services
          for additional functionalities. We are not responsible for the content
          or practices of these third parties.
        </p>

        <h2 className="font-bold text-xl mb-2">Indemnification</h2>
        <p className=" mb-7">
          Patients agree to indemnify and hold harmless our service, its
          affiliates, and practitioners from any claims, damages, or liabilities
          arising from their use of the e-consultation services.
        </p>

        <h2 className="font-bold text-xl mb-2">No Guarantee of Results</h2>
        <p className=" mb-7">
          While we strive to provide the highest standard of care, we do not
          guarantee specific outcomes from consultations. The effectiveness of
          treatment may vary based on individual circumstances.
        </p>

        <h2 className="font-bold text-xl mb-2">Termination of Service</h2>
        <p className=" mb-7">
          We reserve the right to terminate or suspend a patient's access to
          e-consultation services for violations of these terms and conditions
          or other inappropriate conduct.
        </p>

        <h2 className="font-bold text-xl mb-2">Feedback and Complaints</h2>
        <p className=" mb-7">
          We welcome patient feedback and are committed to addressing any
          concerns. Patients can submit complaints or suggestions through email
          at{" "}
          <a href="mailto:customer@medpharma.care">customer@medpharma.care</a>.
        </p>

        <h2 className="font-bold text-xl mb-2">Governing Law</h2>
        <p className=" mb-7">
          These terms and conditions are governed by the laws of Ghana. Any
          disputes arising from the use of our services will be subject to the
          exclusive jurisdiction of the Ghana legal system.
        </p>

        <h2 className="font-bold text-xl mb-2">
          Changes to Terms and Conditions
        </h2>
        <p className=" mb-7">
          We may update these terms and conditions periodically. Patients will
          be notified of significant changes, and continued use of the service
          constitutes acceptance of the revised terms.
        </p>

        <h2 className="font-bold text-xl mb-2">Use of Cookies</h2>
        <p className=" mb-7">
          Our website may use cookies to enhance user experience and gather
          analytics data. Patients can manage cookie settings through their
          browser preferences.
        </p>

        <h2 className="font-bold text-xl mb-2">User Account Security</h2>
        <p className=" mb-7">
          Patients are responsible for maintaining the confidentiality of their
          account information and for all activities that occur under their
          account. Any unauthorized use must be reported immediately.
        </p>

        <h2 className="font-bold text-xl mb-2">Use of Personal Information</h2>
        <p className=" mb-7">
          We may use personal information for purposes including but not limited
          to providing medical care, billing, health tips and improving our
          services. Detailed information on data usage is available in our
          Privacy Policy.
        </p>

        <h2 className="font-bold text-xl mb-2">Prohibited Uses</h2>
        <p className=" mb-7">
          Patients agree not to use the e-consultation services for any unlawful
          purpose, including but not limited to fraudulent activities,
          impersonation, or the transmission of harmful software.
        </p>

        <h2 className="font-bold text-xl mb-2">Service Availability</h2>
        <p className=" mb-7">
          We strive to ensure our e-consultation services are available at all
          times, but we do not guarantee uninterrupted access. Scheduled
          maintenance, technical issues, or unforeseen circumstances may affect
          availability.
        </p>

        <h2 className="font-bold text-xl mb-2">Follow-Up Care</h2>
        <p className=" mb-7">
          Patients may need follow-up consultations based on the initial
          assessment and treatment plan. These follow-ups would be conducted via
          e-consultation or in-person hospital visits as advised by the
          practitioner.
        </p>

        <h2 className="font-bold text-xl mb-2">
          Emergency Contact Information
        </h2>
        <p className=" mb-7">
          Patients should ensure that their contact information is up-to-date
          and that they have a reliable means of communication for urgent or
          follow-up care, if necessary.
        </p>

        <h2 className="font-bold text-xl mb-2">Specialist Referrals</h2>
        <p className=" mb-7">
          If our practitioners determine that a patient requires specialized
          care beyond the scope of e-consultation, they may refer the patient to
          an appropriate specialist or in-person healthcare provider.
        </p>

        <h2 className="font-bold text-xl mb-2">Consent for Recording</h2>
        <p className=" mb-7">
          For quality assurance and training purposes, consultations may be
          recorded with patient consent. Recordings are securely stored and
          protected according to our privacy policy.
        </p>

        <h2 className="font-bold text-xl mb-2">Patient Responsibilities</h2>
        <p className=" mb-7">
          Patients are responsible for following the treatment plan prescribed
          by the practitioner and attending all scheduled follow-up
          appointments. Failure to do so may affect the effectiveness of the
          treatment.
        </p>

        <h2 className="font-bold text-xl mb-2">Contacting Practitioners</h2>
        <p className=" mb-7">
          Patients should use MCare only as a means of communicating with
          medical experts on the platform for medical advice or follow-up
          questions. Direct communication outside these channels is highly
          discouraged and may result in suspension from the platform.
        </p>

        <h2 className="font-bold text-xl mb-2">Medical Regulation</h2>
        <p className=" mb-7">
          Our e-consultation services are subject to regulations of the Medical
          and Dental Council of Ghana, and Health Facilities Regulatory Agency
          (HeFRA). By using our services, patients agree to the terms and
          conditions for complying with these regulations.
        </p>

        <h2 className="font-bold text-xl mb-2">Service Use for Children</h2>
        <p className=" mb-7">
          Children aged 0-18 must have a parent or guardian present during the
          consultation to ensure appropriate care and communication. Parents or
          guardians are responsible for adhering to these guidelines.
        </p>

        <h2 className="font-bold text-xl mb-2">International Use</h2>
        <p className=" mb-7">
          Our services are primarily intended for use within Ghana. If patients
          are accessing our services from outside Ghana, they are responsible
          for complying with local laws and regulations.
        </p>

        <h2 className="font-bold text-xl mb-2">
          Complaints and Dispute Resolution
        </h2>
        <p className=" mb-7">
          For complaints and disputes, please contact{" "}
          <a href="mailto:customer@medpharma.care">customer@medpharma.care</a>.
          We will review and respond to complaints within a reasonable timeframe
          and seek to resolve disputes amicably.
        </p>

        <h2 className="font-bold text-xl mb-2">Delivery Confirmation</h2>
        <p className=" mb-7">
          Clients or their authorized representatives must be available to
          receive medication deliveries at the specified delivery address.
          Delivery confirmation may be required upon receipt of medications.
        </p>
        <h2 className="font-bold text-xl mb-2">Refunds and Returns</h2>
        <p className=" mb-7">
          Refunds or returns for delivered medications may be available in
          accordance with our refund policy. Clients should contact customer
          support customer@medpharma.care for assistance with refunds or
          returns.
        </p>
        <h2 className="font-bold text-xl mb-2">Storage and Handling</h2>
        <p className=" mb-7">
          We take precautions to ensure the safe storage and handling of
          medications during delivery. However, clients should inspect
          medications upon receipt and report any concerns or discrepancies
          immediately.
        </p>
        <h2 className="font-bold text-xl mb-2">Restricted Items</h2>
        <p className=" mb-7">
          Certain medications may be restricted or require additional
          documentation for delivery. Clients will be notified of any
          restrictions or requirements at the time of ordering.
        </p>
        <h2 className="font-bold text-xl mb-2">Communication Preferences</h2>
        <p className=" mb-7">
          Patients can specify their preferred method of communication for
          receiving notifications and reminders. We will strive to honor these
          preferences within the limits of our system capabilities.
        </p>
        <h2 className="font-bold text-xl mb-2">E-consultation Equipment</h2>
        <p className=" mb-7">
          Any equipment provided by our service for e-consultation consultations
          must be used according to the provided instructions and returned in
          good condition if applicable.
        </p>
        <h2 className="font-bold text-xl mb-2">Survivability</h2>
        <p className=" mb-7">
          If any provision of these terms and conditions is found to be
          unenforceable, the remaining provisions will continue to be valid and
          enforceable to the fullest extent permitted by law.
        </p>
        <h2 className="font-bold text-xl mb-2">Medical Records Repository</h2>
        <p className=" mb-7">
          Our e-consultation service also acts as a repository for medical
          records generated during consultations. Patients' medical information,
          including consultation notes and treatment plans, is securely stored
          in accordance with applicable laws and regulations.
        </p>
        <h2 className="font-bold text-xl mb-2">Patient Support</h2>
        <p className=" mb-7">
          We offer patient support for technical issues, appointment scheduling,
          and general inquiries. Support can be reached via
          customer@medpharma.care during business hours.
        </p>
        <h2 className="font-bold text-xl mb-2">Language and Interpretation</h2>
        <p className=" mb-7">
          E-consultation consultations are largely conducted in English and if
          possible, other local Ghanaian languages in the event that a patient
          and doctor face language and communication challenges, we will
          reschedule the e-consultation and reassign the patient to another
          doctor who can speak their language.
        </p>
        <h2 className="font-bold text-xl mb-2">Patient Feedback</h2>
        <p className=" mb-7">
          We value patient feedback and use it to improve our services. Feedback
          can be submitted through our website or directly to our customer
          service team.
        </p>
        <h2 className="font-bold text-xl mb-2">Community Guidelines</h2>
        <p className=" mb-7">
          Patients are expected to adhere to our community guidelines, which
          promote respectful and constructive interactions within our
          e-consultation platform.
        </p>
        <h2 className="font-bold text-xl mb-2">User Account Creation</h2>
        <p className=" mb-7">
          To access our services, patients must create an account by providing
          accurate and complete information. Patients are responsible for
          maintaining the confidentiality of their account credentials.
        </p>
        <h2 className="font-bold text-xl mb-2">Service Accessibility</h2>
        <p className=" mb-7">
          Our e-consultation platform is designed to be accessible to patients
          with disabilities. If you experience any accessibility issues, please
          contact our support team for assistance.
        </p>
        <h2 className="font-bold text-xl mb-2">Right to Refuse Service</h2>
        <p className=" mb-7">
          We reserve the right to refuse service to any patient who does not
          comply with our terms and conditions or engages in inappropriate
          behavior during consultations.
        </p>
        <h2 className="font-bold text-xl mb-2">
          Use of Artificial Intelligence
        </h2>
        <p className=" mb-7">
          Our service may incorporate artificial intelligence (AI) to assist in
          providing care. Patients consent to the use of AI in their
          consultations, understanding that it is supervised by qualified
          medical practitioners.
        </p>
        <h2 className="font-bold text-xl mb-2">No Guarantee of Availability</h2>
        <p className=" mb-7">
          While we strive to provide continuous access to our e-consultation
          services, we do not guarantee availability at all times. Technical
          issues, maintenance, or other factors may affect service availability
          if you have any issues or concerns, kindly reach out to our customer
          service team at customer@medpharma.care.
        </p>
        <h2 className="font-bold text-xl mb-2">
          Marketing and Communications:
        </h2>
        <p className=" mb-7">
          By using our services, patients consent to receive marketing
          communications from us. Patients can opt out of these communications
          at any time by contacting our customer service team at
          customer@medpharma.care
        </p>
        <h2 className="font-bold text-xl mb-2">Non-Transferability</h2>
        <p className=" mb-7">
          Patient accounts and subscriptions are non-transferable. Any attempt
          to transfer or share accounts may result in termination of services.
        </p>
        <h2 className="font-bold text-xl mb-2">Referral Program</h2>
        <p className=" mb-7">
          We may offer referral programs where patients can refer others to our
          services. Specific terms and conditions will apply to such programs,
          which will be detailed separately.
        </p>
        <h2 className="font-bold text-xl mb-2">Force Majeure</h2>
        <p className=" mb-7">
          We are not liable for any failure to perform due to causes beyond our
          reasonable control, including natural disasters, war, or other
          unforeseen circumstances.
        </p>
        <h2 className="font-bold text-xl mb-2">
          Consent to Electronic Communications
        </h2>
        <p className=" mb-7">
          By using our services, patients consent to receive electronic
          communications, including emails and notifications, related to their
          care and account.
        </p>
        <h2 className="font-bold text-xl mb-2">Termination by User</h2>
        <p className=" mb-7">
          Patients may terminate their subscription at any time. Upon
          termination, access to the mcare services will cease at the end of the
          current billing cycle.
        </p>
        <h2 className="font-bold text-xl mb-2">Medication Delivery Service</h2>
        <p className=" mb-7">
          We offer medication delivery services to our clients for added
          convenience. Delivery times may vary depending on factors such as
          location and availability of medications.
        </p>
        <h2 className="font-bold text-xl mb-2">Delivery Fees</h2>
        <p className=" mb-7">
          Delivery fees may apply to medication deliveries and will be
          communicated to clients at the time of ordering. Fees may vary based
          on factors such as distance.
        </p>
        <h2 className="font-bold text-xl mb-2">Delivery Address Accuracy</h2>
        <p className=" mb-7">
          Clients are responsible for providing accurate delivery addresses and
          contact information to ensure successful delivery of medications. Any
          errors in the provided information may result in delays or
          unsuccessful deliveries.
        </p>
        <h2 className="font-bold text-xl mb-2">Delivery Disputes</h2>
        <p className=" mb-7">
          In the event of delivery disputes, clients should contact our customer
          support team for resolution. We will investigate the issue and work to
          resolve it in a timely manner.
        </p>
        <h2 className="font-bold text-xl mb-2">
          Delivery Terms Subject to Change
        </h2>
        <p className=" mb-7">
          Delivery terms and conditions are subject to change without prior
          notice. Clients will be informed of any changes that may affect their
          medication delivery services.
        </p>
        <h2 className="font-bold text-xl mb-2">Delivery Partners</h2>
        <p className=" mb-7">
          We may utilize third-party delivery partners to fulfill medication
          deliveries. Clients agree to comply with the terms and conditions of
          these delivery partners while using our medication delivery services.
        </p>
        <h2 className="font-bold text-xl mb-2">
          Emergency Medication Delivery
        </h2>
        <p className=" mb-7">
          In emergency situations where immediate medication delivery is
          necessary, clients should contact our emergency hotline for
          assistance. Additional charges may apply for emergency delivery
          services.
        </p>
        <h2 className="font-bold text-xl mb-2">
          Feedback on Delivery Services
        </h2>
        <p className=" mb-7">
          We welcome feedback from clients regarding their medication delivery
          experience. Clients can provide feedback through our website or
          directly to customer support.
        </p>

        <div className="w-full flex flex-col items-center bg-[#E1F2FEB5]">
          {/* <div className="mt-8 w-[70%]">
            <Collapse
              defaultActiveKey={[]}
              onChange={onChange}
              expandIconPosition="right"
              //   items={items}
              className="w-full bg-white"
              bordered
              //   size="small"
            />
          </div> */}
          <Button
            onClick={() => navigate("/")}
            size="large"
            className="bg-white mt-8 px-10 rounded"
          >
            Go to home
          </Button>
        </div>
      </div>
      <Footer2 />
    </div>
  );
}
