/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import DataProvider from "../../../services";
import { Button, notification } from "antd";
import { useParams } from "react-router-dom";
import { useAppStore } from "../../../store";
import LoadWrapper from "../../../components/elements/load-wrapper";
import Empty from "../../../components/elements/empty";
import { GiCheckMark } from "react-icons/gi";
import SelectPlan from "./select-plan";
import Pay from "./pay";
import Confirm from "./confirm";
import img from "../../assets/images/poweredbymtn.png";
import ChangePlan from "./change-plan";
import moment from "moment";

export default function Mcare(props: any) {
  const { user, setPlan, setState } = useAppStore();
  const params = useParams();
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [screen, setScreen] = useState<number>(0);
  const [subscription, setSubscription] = useState<any | null>(null);

  const getSubscription = useCallback(async () => {
    setLoading(true);
    const { data } = await DataProvider.getUserSubscription();
    // if (data && data.message === "Not a subscriber!") {
    //   setLoading(false);
    //   // return api["warning"]({
    //   //   message: "Subscription",
    //   //   description: "You are not subscribed to any package.",
    //   // });
    // } else if (data && data.status !== "active") {
    //   const msg =
    //     data.status === "pending"
    //       ? "Your subscription was not successful, kindly subscribe."
    //       : data.status === "expired"
    //       ? "Your subscribed package is expired, Kindly renew!"
    //       : data.status === "ActivationFailed"
    //       ? "Your subscription avtivation failed."
    //       : data.status === "cancelled"
    //       ? "You are not subscribed to any package. Kindly subscribe."
    //       : "";
    //   setSubscription(data);
    //   setLoading(false);
    //   // return api["warning"]({
    //   //   message: "Warning",
    //   //   description: msg,
    //   // });
    // }
    setSubscription(data);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (params.id === "2" && screen === 0) {
      getSubscription();
    }
  }, [params, getSubscription, screen]);

  return (
    <div>
      <div className="bg-white border border-solid border-gray-200 rounded-lg w-full min-h-[75vh] flex justify-center items-center py-3 px-3">
        {screen === 0 && (
          <LoadWrapper loading={loading}>
            {loading ? null : !loading &&
              subscription !== null &&
              subscription.status === "active" ? (
              <div className="w-full h-full grid md:grid-cols-2 gap-5 grid-cols-1">
                <div className="border border-solid border-gray-200 rounded-lg md:p-4 p-2">
                  <div>
                    <h4 className="font-semibold">Plan</h4>
                    <h2 className="text-[#3498db] font-semibold text-xl">
                      {subscription.mcare.description}
                    </h2>
                  </div>
                  <div className="">
                    {subscription.mcare.offers.map((itx: any, idx: any) => (
                      <div className="my-4" key={idx}>
                        <h4 className="font-semibold mb-1">{itx.name}</h4>
                        <ul>
                          {itx.list.map((i: any, idxx: any) => (
                            <li key={idxx} className="flex items-center">
                              <GiCheckMark
                                size={14}
                                className="text-[#1A94EC] mr-2"
                              />
                              {i}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="border border-solid border-gray-200 rounded-lg md:p-4 p-2">
                  <div>
                    <h2 className="font-semibold text-xl">
                      {subscription.mcare.description}
                    </h2>
                    <p className="font-medium">
                      Membership Number: {subscription.code}
                    </p>
                    <p>Number: {subscription.msisdn}</p>
                    <p>
                      Next billing date:{" "}
                      {subscription.endDate
                        ? moment(subscription.endDate).format("Do MMMM, YYYY")
                        : "Expired"}
                    </p>
                    <p>
                      You will be charged GHC{subscription.mcare.price} on the
                      billing date.
                    </p>
                  </div>
                  <div className="my-4">
                    {/* Render "Renew your plan" button only if the status is not "active" */}
                    {subscription.status !== "active" && (
                      <Button
                        onClick={() => {
                          setState("RENEW");
                          setPlan(subscription.mcare);
                          setScreen(2);
                        }}
                        size="large"
                        style={{ backgroundColor: "#1A94EC", color: "white" }}
                        className="w-full text-white"
                      >
                        Renew your plan
                      </Button>
                    )}
                    <Button
                      onClick={() => {
                        setPlan(subscription.mcare);
                        setState("CHANGE");
                        setScreen(4);
                      }}
                      size="large"
                      className="w-full mt-4"
                    >
                      Change Plan
                    </Button>
                  </div>
                </div>
              </div>
            ) : subscription &&
              (subscription.status === "pending" ||
                subscription.status === "ActivationFailed") ? (
              <Empty
                imageStyle="md:h-44 h-24"
                titleStyle="md:text-lg text-sm"
                desStyle="md:text-base"
                title="Mcare"
                buttons={[
                  {
                    name: "Refresh",
                    event: () => getSubscription(),
                  },
                  {
                    name: "Subscribe",
                    type: "primary",
                    event: () => {
                      setState("SUB");
                      setScreen(1);
                    },
                  },
                ]}
                description="You are not on any active plan. Complete payment or Subscribe to a new package."
              />
            ) : subscription && subscription.status === "expired" ? (
              <Empty
                imageStyle="md:h-44 h-24"
                titleStyle="md:text-lg text-sm"
                desStyle="md:text-base"
                title="Mcare"
                buttons={[
                  {
                    name: "Refresh",
                    event: () => getSubscription(),
                  },
                  {
                    name: `Renew ${subscription.mcare.description}`,
                    type: "primary",
                    event: () => {
                      setState("RENEW");
                      setPlan(subscription.mcare);
                      setScreen(2);
                    },
                  },
                  {
                    name: "Change Plan",
                    type: "primary",
                    event: () => {
                      setPlan(subscription.mcare);
                      setState("CHANGE");
                      setScreen(1);
                    },
                  },
                ]}
                description="Your subscription is expired."
              />
            ) : subscription && subscription.status === "cancelled" ? (
              <Empty
                imageStyle="md:h-44 h-24"
                titleStyle="md:text-lg text-sm"
                desStyle="md:text-base"
                title="Mcare"
                buttons={[
                  {
                    name: "Refresh",
                    event: () => getSubscription(),
                  },
                  {
                    name: "Subscribe",
                    type: "primary",
                    event: () => {
                      setState("SUB");
                      setScreen(1);
                    },
                  },
                ]}
                description="You are not subscribed to any package. Kindly subscribe."
              />
            ) : (
              <Empty
                imageStyle="md:h-44 h-24"
                titleStyle="md:text-lg text-sm"
                desStyle="md:text-base"
                title="Mcare"
                buttons={[
                  {
                    name: "Refresh",
                    event: () => getSubscription(),
                  },
                  {
                    name: "Subscribe",
                    type: "primary",
                    event: () => {
                      setState("SUB");
                      setScreen(1);
                    },
                  },
                ]}
                description="You are not on any active plan."
              />
            )}
          </LoadWrapper>
        )}
        {screen === 1 && <SelectPlan screen={screen} setScreen={setScreen} />}
        {screen === 2 && <Pay screen={screen} setScreen={setScreen} />}
        {screen === 3 && <Confirm screen={screen} setScreen={setScreen} />}
        {screen === 4 && <ChangePlan screen={screen} setScreen={setScreen} />}
      </div>

      {contextHolder}
    </div>
  );
}
