import React from "react";
import mcarehd from "../../../../assets/images/mcarehd.png";
import mtnyellow from "../../../../assets/images/mtnyellow.png";
import ayoba from "../../../../assets/images/ayoba.png";
import logo from "../../../../assets/images/logo.png";

export default function Footer2() {
  return (
    <div>
      <div className="bg-gray-900">
        <div className="container p-6 mx-auto">
          <div className="lg:flex">
            <div className="w-full -mx-6 lg:w-2/5">
              <div className="px-6">
                <a href="/">
                  <img
                    src={mcarehd}
                    alt="care"
                    className="h-8 md:h-10 rounded-l-lg"
                  />
                  {/* <img className="w-auto h-7" src="https://merakiui.com/images/full-logo.svg" alt="" /> */}
                </a>

                <p className="max-w-sm mt-2 text-gray-500 dark:text-gray-400">
                  Stay connected with us for updates on new services and health
                  tips.
                </p>

                <div className="flex mt-9 -mx-2 ">
                  <a
                    href="/"
                    className="mx-2 text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
                    aria-label="Reddit"
                  >
                    <img
                      src={mtnyellow}
                      alt="care"
                      className=" h-5 fill-current"
                    />
                  </a>

                  <a
                    href="/"
                    className="mx-2 text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
                    aria-label="Facebook"
                  >
                    <img src={ayoba} alt="care" className=" h-5 fill-current" />
                  </a>

                  <a
                    href="/"
                    className="mx-2 text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
                    aria-label="Github"
                  >
                    <img src={logo} alt="care" className=" h-5 fill-current" />
                  </a>
                </div>
              </div>
            </div>

            <div className="mt-6 lg:mt-0 lg:flex-1">
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 ">
                <div>
                  <h3 className="text-gray-700 uppercase dark:text-white">
                    Why Choose Us?
                  </h3>
                  <a
                    href="/faq"
                    className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline"
                  >
                    FAQs
                  </a>
                  <a
                    href="/terms"
                    className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline"
                  >
                    Terms and Conditions
                  </a>
                  <a
                    href="/"
                    className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline"
                  >
                    Home
                  </a>
                </div>

                <div>
                  <h3 className="text-gray-700 uppercase dark:text-white">
                    Products
                  </h3>
                  <a
                    href="/packages"
                    className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline"
                  >
                    Packages
                  </a>
                  {/* <a
                    href="/"
                    className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline"
                  >
                    Silver Package
                  </a> */}
                </div>

                <div>
                  <h3 className="text-gray-700 uppercase dark:text-white">
                    Contact
                  </h3>
                  <span className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">
                    <a
                      href="tel:+233303000448"
                      className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline"
                    >
                      +233 30 300 0448
                    </a>
                  </span>
                  <span className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">
                    <a
                      href="mailto:customer@medpharma.care"
                      className="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline"
                    >
                      customer@medpharma.care
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <hr className="h-px my-6 bg-gray-300 border-none dark:bg-gray-700" />

          <div>
            <p className="text-center text-gray-800 dark:text-white">
              © Medpharma. All rights reserved 2024
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
