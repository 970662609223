import React, { useState } from "react";
import mcarehd from "../../../../assets/images/mcarehd.png";
import mtnn from "../../../../assets/images/mtnyellow.png";
import logo from "../../../../assets/images/md_logo.png";
import { useNavigate } from "react-router-dom";
import { IoLogInOutline } from "react-icons/io5";
import { GiHamburgerMenu } from "react-icons/gi";
import CustomDrawer from "./drawer";

export default function Nav() {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const showDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <nav className="shadow-lg bg-white w-full flex justify-between items-center rounded-lg h-16 px-3">
      <div className="h-full flex flex-row items-center gap-2">
        <img src={mtnn} alt="care" className="md:h-8 h-4 rounded-l-lg" />
        <img src={logo} alt="care" className="md:h-7 h-4 rounded-l-lg" />
        <img src={mcarehd} alt="care" className="md:h-8 h-4 rounded-l-lg" />
      </div>

      <div className="md:flex hidden gap-10">
        <button
          onClick={() => navigate("/sign-in")}
          className="flex items-center justify-center gap-2"
        >
          <IoLogInOutline className="text-3xl" />
          Login
        </button>
        <button
          onClick={() => navigate("/sign-up")}
          className="bg-[#FFCC08] px-8 py-2 cursor-pointer rounded-full"
        >
          Register
        </button>
      </div>
      <div className="h-full flex items-center md:hidden cursor-pointer gap-3">
        <button
          onClick={() => navigate("/sign-in")}
          className="flex items-center justify-center gap-1"
        >
          <IoLogInOutline size={20} className="text-3xl" />
          Login
        </button>
        <button className="text-gray-700 " onClick={showDrawer}>
          <GiHamburgerMenu size={20} />
        </button>
        <CustomDrawer open={drawerOpen} onClose={closeDrawer} />
      </div>
    </nav>
  );
}
