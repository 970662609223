import React from "react";
import Header from "./components/header";
import econsult from "../home-one/assets/econsult.png";
import prescription from "../home-one/assets/prescription.png";
import labs from "../home-one/assets/labs.png";
import serviceprovider from "../home-one/assets/serviceprovider.png";
import medrecords from "../home-one/assets/medrecords.png";
import Hero from "./components/hero";
import ChatInterface from "../../../components/sections/chatai";

export default function HomeTwo() {
  return (
    <div>
      <Header />
      <div className="flex justify-center w-full lg:hidden p-4">
        <div className="border w-full">
          <ChatInterface />
        </div>
      </div>
      <div className="w-full grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 justify-center items-center xl:gap-12 gap-4 pt-10 bg-white min-h-60 xl:px-16 lg:px-5 px-4">
        {diagnosis.map((i, index) => (
          <div
            key={index}
            className="flex flex-col h-64 justify-center items-center"
          >
            <div
              className="rounded-3xl h-[95%] flex justify-center items-center w-full border overflow-hidden"
              style={{ background: i.bg }}
            >
              {i.img && <img src={i.img} alt="" className="h-52 " />}
            </div>
            <p className="my-2">{i.name}</p>
          </div>
        ))}
      </div>

      <Hero />
    </div>
  );
}

const diagnosis = [
  {
    name: "E-consultation",
    bg: "#FFEE5F",
    img: econsult,
  },
  {
    name: "Upload your Prescription",
    bg: "#FFA3BC",
    img: prescription,
  },
  {
    name: "Book a Lab and Diagnostic Test",
    bg: "#FFCB05",
    img: labs,
  },
  {
    name: "Medical Records",
    bg: "#3388CB",
    img: medrecords,
  },
  {
    name: "Over 500 service providers",
    bg: "#C1FFBF",
    img: serviceprovider,
  },
];
