import React from "react";
import doc from "../../assets/images/user.png";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Button, Rate } from "antd";
import { VideoCameraOutlined } from "@ant-design/icons";
// import { useAppStore } from "../../store";

export default function AppointmentCard(props: any) {
  // const { user, logOut } = useAppStore();

  const navigate = useNavigate();
  const { data } = props;

  return (
    <div
      onClick={() => navigate(`/main/appointments/${data.id}`)}
      className="py-4 m-2 cursor-pointer rounded shadow-sm w-80 sm:w-80 bg-gray-50 "
    >
      {/* <h2 className="lg:text-3xl text-lg flex font-semibold">
        Welcome back,
        <span className="text-green-600">
          {user && user.fullname && user.fullname}
        </span>
      </h2> */}
      <div className="flex p-4 space-x-4 sm:px-8">
        <div className="flex-shrink-0 w-16 h-16 rounded-full bg-gray-300">
          <img
            src={data.profile_image ? data.profile_image : doc}
            alt=""
            className=""
          />
        </div>
        <div className="flex-1 space-y-3">
          <div className="w-full h-5 rounded font-semibold text-sm overflow-hidden whitespace-pre-wrap text-ellipsis">
            {data.doctorName}
          </div>
          <div className="h-2 rounded text-gray-500 text-xs">
            {/* {data.doctor.institution} */}
            200hrs of consultations done
          </div>
          <div className="text-sm">
            <Rate
              className="text-sm"
              allowHalf
              defaultValue={4.5}
              // style={{ height: 30 }}
            />
          </div>
        </div>
      </div>
      <div className="px-4 space-y-1 sm:px-8">
        <p>Appointment Schedule</p>
        <div className="rounded text-gray-500 text-xs">
          {`${moment(data.date, "DD-MM-YYYY").format("Do MMMM, YYYY")}`}
        </div>
        <div className="flex rounded">
          <span className="text-xs text-gray-500"> From {data.startTime}</span>{" "}
          <span className="text-xs text-gray-500 mx-2">To</span>
          <span className="text-xs text-gray-500">{data.endTime}</span>
        </div>
        <p>Date Created</p>
        <div className="text-xs text-gray-500">{`${moment(
          data.createdAt
        ).format("Do MMMM, YYYY hh:mm a")}`}</div>
      </div>
      <div className="pt-4 px-4 sm:px-8">
        <Button
          type="primary"
          size="middle"
          disabled={moment(data.date, "DD-MM-YYYY").isBefore(moment(), "day")}
          className="bg-[#06509E] flex items-center justify-center font-semibold w-full sm:w-auto mb-2"
        >
          <VideoCameraOutlined />
          <span className="ml-2">Join Video Meeting</span>
        </Button>
      </div>
    </div>
  );
}
