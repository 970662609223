import React, { useState } from "react";
import { Button, Drawer } from "antd";
import { FaBell } from "react-icons/fa";
import {
  MODAL_TYPES,
  useGlobalModalContext,
} from "../../context/global-modal-context";
import type { MenuProps } from "antd";
import { Layout, Menu } from "antd";
import { NavLink } from "react-router-dom";
import {
  CaretDownOutlined,
  MenuUnfoldOutlined,
  CalendarFilled,
  HomeFilled,
  UserOutlined,
  UserSwitchOutlined,
  LogoutOutlined,
  FileDoneOutlined,
  FileTextOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { useAppStore } from "../../store";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

export default function Header(props: any) {
  const { user, logOut } = useAppStore();
  const { Sider } = Layout;
  const [open, setOpen] = useState(false);
  const { showModal } = useGlobalModalContext();
  const openUserModal = () => {
    showModal(MODAL_TYPES.NOTIFICATION_MODAL, {
      title: "Notifications",
      confirmBtn: "Ok",
    });
  };

  function onLogout() {
    logOut();
  }

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const items: MenuItem[] = [
    getItem(<NavLink to="/main">Home</NavLink>, "1", <HomeFilled />),
    getItem(
      <NavLink to="/main/appointments" className="mr-2">
        Appointments
      </NavLink>,
      "2",
      <CalendarFilled />
    ),
    getItem(
      <NavLink to="/main/prescriptions">Prescriptions</NavLink>,
      "3",
      <FileDoneOutlined />
    ),
    getItem(
      <NavLink to="/main/claims">Claims</NavLink>,
      "4",
      <FileTextOutlined />
    ),
    getItem(
      <NavLink to={`/main/profile/1`}>Profile</NavLink>,
      "5",
      <UserOutlined size={20} />
    ),
    getItem(
      <NavLink to="/main/profile/2">Mcare</NavLink>,
      "8",
      <SolutionOutlined size={20} />
    ),
    getItem(
      <NavLink to="/main/unsubscribe">Unsubscribe</NavLink>,
      "6",
      <UserSwitchOutlined size={17} />
    ),
    getItem(
      <div onClick={onLogout}>Logout</div>,
      "7",
      <LogoutOutlined size={20} />
    ),
  ];

  return (
    <div className="flex justify-between items-center bg-white h-full w-full lg:px-8 px-4">
      <div className="">
        <h2 className="lg:text-3xl text-lg flex font-semibold">
          Welcome back,
          <span className="text-green-600">
            {user && user.fullname && user.fullname}
          </span>
        </h2>
        <p className="lg:leading-9 md:text-base text-xs text-gray-500">
          How can we help you stay healthy today?
        </p>
      </div>

      <div className={`lg:block hidden ${props.collapsed ? "mr-24" : "mr-52"}`}>
        <Button
          onClick={openUserModal}
          className="flex justify-evenly items-center "
        >
          <FaBell className="mx-2" />
          Notification
        </Button>
      </div>
      <div className="lg:hidden flex items-center ">
        <div className="">
          <Button
            type="link"
            onClick={openUserModal}
            className="flex justify-evenly items-center text-2xl text-black"
          >
            <FaBell className="" />
          </Button>
        </div>
        <Button
          size="large"
          onClick={showDrawer}
          className="text-xl flex justify-center items-center"
        >
          <MenuUnfoldOutlined />
        </Button>
      </div>
      <Drawer
        title="Medpharma"
        width={300}
        onClose={onClose}
        open={open}

        // bodyStyle={{ padding: 0 }}
      >
        <Sider
          trigger={null}
          collapsible
          theme="light"
          className="h-full w-full flex flex-col justify-between"
        >
          <Menu
            mode="inline"
            theme="light"
            items={items}
            className="menu bg-transparent"
            onClick={onClose}
            overflowedIndicator={<CaretDownOutlined />}
          />

          <div className="px-4 py-2 text-sm text-gray-600 dark:text-gray-400 mt-4">
            <p>Call our toll-free number for assistance</p>
            <a
              href="tel:+233303000448"
              className="block mt-1 text-gray-600 dark:text-gray-400 hover:underline"
            >
              +233 30 300 0448
            </a>
          </div>
        </Sider>
      </Drawer>
    </div>
  );
}
