import React from "react";
import img from "../../assets/images/mcarehd.png";
import { Link } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";
import Nav from "../landing-pages/home-two/components/nav";
import Footer2 from "../landing-pages/home-two/components/footer2";

const packages = [
  {
    id: 1,
    name: "Silver Package",
    sub: "Telemedicine",
    amount: 10,
    offer: [
      "E-consultation (GPs & Some selected specialists)",
      "E-prescription request (multiple)",
      "Free encrypted medical records",
      "Access to medical records on the app",
      "Discounted prices on medication",
      "Discounted delivery of medication",
      "Freely chat with our Chatbot (Kobikuul) for assistance",
      "24 hours customer service",
      "Stress-free access to healthcare",
    ],
  },
  {
    id: 2,
    name: "Gold Package",
    sub: "Telemedicine + Health Insurance",
    amount: 161.66,
    offer: [
      "E-consultation (GPs & Some selected specialists)",
      "E-prescription request (multiple)",
      "Free encrypted medical records",
      "Access to medical records on the app",
      "Discounted prices on medication",
      "Discounted delivery of medication",
      "Freely chat with our Chatbot (Kobikuul) for assistance",
      "24 hours customer service",
      "Stress-free access to healthcare",
      "Outpatient care cover of GHS 1500 annually",
      "In-patient care cover of GHS 6,500 annually",
      "Over 500 service providers nationwide",
    ],
  },
];

export default function Packages() {
  return (
    <div className="min-h-screen bg-gray-100">
      <Nav />

      {/* Hero Section */}
      <div
        className="w-full flex justify-center items-center h-[30vh] sm:h-[50vh] bg-cover bg-[#1A94ECB5] bg-blend-overlay"
        style={{ backgroundImage: `url(${img})` }}
      >
        <div className="ml-4 sm:ml-10 flex flex-col justify-center items-center mt-10 sm:mt-20">
          <h1 className="text-2xl sm:text-4xl text-white font-bold text-center">
            Mcare Packages
          </h1>
          <div className="mt-4 flex flex-row items-center text-sm sm:text-lg">
            <Link
              to="/"
              className="text-[#ffffff] hover:text-gray-300 font-medium"
            >
              Home
            </Link>
            <div className="mx-2">
              <RightOutlined style={{ color: "white", fontSize: "15px" }} />
            </div>
            <Link to="" className="text-[#ffffff] font-medium">
              Packages
            </Link>
          </div>
        </div>
      </div>

      {/* Package Section */}
      <div className="mt-5 px-4 sm:px-8  ">
        <h1 className="text-2xl sm:text-3xl font-bold text-center mb-8 text-gray-800">
          Choose Your Package
        </h1>
        <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 gap-6 mb-20">
          {packages.map((pkg) => (
            <div
              key={pkg.id}
              className="bg-white rounded-lg shadow-lg p-4 sm:p-6 hover:shadow-2xl transition duration-300 flex flex-col justify-between h-full"
            >
              {/* Package Details */}
              <div>
                <h2 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-2">
                  {pkg.name}
                </h2>
                <p className="text-gray-600 text-sm mb-4">{pkg.sub}</p>
                <p className="text-2xl sm:text-3xl font-bold text-gray-900 mb-4">
                  Gh¢ {pkg.amount.toFixed(2)}
                  <span className="text-sm text-gray-500">/month</span>
                </p>
                <ul className="mb-4 space-y-2">
                  {pkg.offer.map((item, index) => (
                    <li
                      key={index}
                      className="flex items-center space-x-2 text-gray-700"
                    >
                      <span className="text-green-500">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={2}
                          stroke="currentColor"
                          className="w-4 sm:w-5 h-4 sm:h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </span>
                      <span className="text-sm sm:text-base">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Buy Button */}
              <Link to="/sign-up">
                <button className="w-full bg-[#F7CB31] text-black py-2 rounded-lg font-semibold hover:bg-yellow-400 transition duration-300 mt-auto">
                  Buy {pkg.name}
                </button>
              </Link>
            </div>
          ))}
        </div>
      </div>

      <Footer2 />
    </div>
  );
}
